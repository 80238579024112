import Vue from 'vue'
import Router from 'vue-router'
import App from './views/App.vue'
import Home from './views/Home.vue'
import Meals from './views/info/Meals.vue'
import Activities from './views/info/Activities.vue'
import News from './views/info/News.vue'
import Camera from './components/Camera.vue'
import TextMessage from './components/TextMessage.vue'
import BeforeUpload from './components/BeforeUpload.vue'
import Login from './views/auth/Login.vue'
import Register from './views/auth/Register.vue'
import SocialAuthenticated from './views/auth/SocialAuthenticated.vue'
import store from './store'
import LinkResident from './views/LinkResident.vue'
import Settings from './views/Settings.vue'
import Privacy from './views/Privacy.vue'
import ShareMedia from './components/ShareMedia.vue'
import Users from './components/Users.vue'
import SwitchResident from './components/SwitchResident'
import Notifications from './components/settings/Notifications'
import GeneralSettings from './components/settings/GeneralSettings'
import VideoCall from './components/videocall/VideoCall'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy,
    },
    {
      path: '/login/:name?/:code?',
      name: 'login',
      component: Login,
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
    },
    {
      path: '/social-authenticated',
      name: 'social-authenticated',
      component: SocialAuthenticated
    },
    // {
    //   path: '/camera',
    //   name: 'camera',
    //   component: Camera,
    //   meta: {
    //     requiresAuth: true
    //   }
    // },
    // {
    //   path: '/before-upload',
    //   name: 'before-upload',
    //   component: BeforeUpload,
    //   props: true,
    //   meta: {
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/first-time-link-resident',
      name: 'first-time-link-resident',
      component: LinkResident,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/',
      component: App,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'camera',
          name: 'camera',
          component: Camera,
        },
        {
          path: 'before-upload',
          name: 'before-upload',
          component: BeforeUpload,
          props: true,
        },
        {
          path: 'link-resident',
          name: 'link-resident',
          component: LinkResident,
        },
        {
          path: '',
          name: 'home',
          component: Home
        },
        {
          path: 'text-message',
          name: 'text-message',
          component: TextMessage
        },
        {
          path: 'meals',
          name: 'meals',
          component: Meals
        },

        {
          path: 'activities',
          name: 'activities',
          component: Activities
        },
        {
          path: 'news',
          name: 'news',
          component: News
        },
        {
          path: 'settings',
          name: 'settings',
          component: Settings,
        },
        {
          path: 'settings-privacy',
          name: 'settings-privacy',
          component: Privacy
        },
        {
          path: 'settings-notifications',
          name: 'settings-notifications',
          component: Notifications
        },
        {
          path: 'settings-general',
          name: 'settings-general',
          component: GeneralSettings
        },
        {
          path: 'settings-users',
          name: 'settings-users',
          component: Users
        },
        {
          path: 'switch-resident',
          name: 'switch-resident',
          component: SwitchResident
        },
        {
          path: 'share-media',
          name: 'share-media',
          component: ShareMedia
        },
        {
          path: 'video-call',
          name: 'video-call',
          component: VideoCall
        }
      ]
    },
    // {
    //   path: '/new-fp-user/:mac',
    //   name: 'new-fp-user',
    //   component: NewFamilyPortalUser,
    //   beforeEnter: (to, from, next) {
    //   // check if user is logged in or not
    //     if(!store.state.loggedIn) {
    //   // if not logged in ask to login or register and remember the resident he wanted to link to
    //       router.push({ name:'lodin', params: { referralUrl: from , mac: to.$params.mac } })
    //     } else {

    //   // if user is logged in check if he is already linked to this resident

    //   // if already linked just show a message

    //   // if not linked ask to confirm the link
    //     }
    //   }


    // }
  ]
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if(requiresAuth) {
    // We check in the store or in localStorage (because the store might not be loaded yet...) to see if the user is logged in.
    // If that is the case we can continue.
    let loggedInViaLocalStorage = false
    if(JSON.parse(localStorage.getItem('store'))) {
      loggedInViaLocalStorage = JSON.parse(localStorage.getItem('store')).loggedIn
    } else {
      loggedInViaLocalStorage = false
    }
    if(store.getters.isLoggedIn || loggedInViaLocalStorage) {
      next()
    } else {
      router.push({ name: 'login', params: { referralUrl: from } })
    }
  } else {
    next()
  }
})

export default router