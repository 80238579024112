<template>
  <div class="container" style="margin-top: 20px;">
    <div class="row" v-if="meals.length > 0">
      <div class="col s12 m6" v-for="meal in meals" :key="meal.id">
        <div class="card">
          <div class="card-image">
            <img :src="mealImage(meal.media[0])" v-if="meal.media.length > 0">
            <div v-else>

            </div>
            <a class="btn-floating halfway-fab waves-effect waves-light red">
              <font-awesome-icon v-if="meal.type == 'breakfast'" icon="mug-hot" size="2x" transform="right-4 down-3" />
              <font-awesome-icon v-if="meal.type == 'lunch'" icon="utensils" size="2x" transform="right-4 down-3" />
              <font-awesome-icon v-if="meal.type == 'snack'" icon="cookie" size="2x" transform="right-4 down-3" />
              <font-awesome-icon v-if="meal.type == 'dinner'" icon="bread-slice" size="2x" transform="right-3 down-3" />
            </a>
          </div>
          <div class="card-content">
            <span class="card-title">{{ localeType(meal.type) }} - {{ formattedDate(meal.date) }}</span>
            <p>{{ meal.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      {{ $t('meals.noMeals') }}
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import swal from "sweetalert";
import { format } from 'date-fns';

import store from '../../store'

export default {
  components: {
  },
  name: "meals",
  data() {
    return {
      meals: store.state.accountForActiveResident.meals
    }
  },
  created() {
  },
  mounted() {
    // update account information when mounted so we have the latest information
    store.dispatch('fetchActiveResidentAccount')
  },
  methods: {
    mealImage(media) {
      return `${process.env.VUE_APP_SERVER_URL}/storage/${media.id}/${media.file_name}`
    },
    formattedDate(date) {
      return format(new Date(date), 'dd-MM')
    },
    localeType(type) {
      if(type == 'breakfast')
      {
        return this.$t('meals.breakfast')
      }
      else if (type == 'lunch')
      {
        return this.$t('meals.lunch')
      }
      else if (type == 'dinner')
      {
        return this.$t('meals.dinner')
      }
      else if (type == 'snack')
      {
        return this.$t('meals.snack')
      }
    }
  }
};
</script>

<style scoped>

</style>