<template>
  <div class="container">
    <div class="row" v-if="activities.length > 0">
      <div class="col s12 m6" v-for="activity in activities" :key="activity.id">
        <div class="card">
          <div class="card-image">
            <img :src="activity.thumbnail" v-if="activity.media.length > 0">
            <img class="activity-placeholder" src="../../assets/activity-placeholder.png" v-else>
            <a class="btn-floating halfway-fab waves-effect waves-light red">
              <font-awesome-icon icon="hiking" size="2x" transform="right-4 down-3" />
            </a>
          </div>
          <div class="card-content">
            <span class="card-title">{{ formattedDate(activity.date) }}</span>
            <p>{{ activity.title }}</p>
            <p>{{ activity.location }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      {{ $t('activities.noActivities') }}
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { format } from 'date-fns';

import store from '../../store'

export default {
  components: {
  },
  name: "activities",
  data() {
    return {
      activities: store.state.accountForActiveResident.activities
    }
  },
  methods: {
    formattedDate(date) {
      return format(new Date(date), 'dd-MM')
    }
  },
  created() {
  },
  mounted() {
    // update account information when mounted so we have the latest information
    store.dispatch('fetchActiveResidentAccount')
  }
};
</script>

<style scoped>
.activity-placeholder {
  margin: auto;
  padding: 10px;
  width: 200px !important;
  height: 200px;
}
</style>