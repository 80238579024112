<template>
<div clas="container">
    <div class="row">
        <div class="label" style="margin-top: 20px;">
            <div class="col s10">
                {{ $t('settings.defaultShare') }}
            </div>
            <div class="col s2">
                <onoff-toggle v-model="currentStatusDefaultSharedValue" theme="material" />
            </div>
        </div>
        <div class="description">
            <div class="col s12" style="margin-top: 20px;">
                <i>{{ $t('settings.defaultShareDesc') }}</i>
            </div>
        </div>
    </div>
    <div class="row" v-if="personalNicknames.length > 0">
        <div class="label" style="margin-top: 20px;">
            <div class="col s12">
                {{ $t('settings.changeNickname') }}
            </div>
        </div>
        <div class="description">
            <div class="label">
                <div class="col s12" style="margin-top: 20px;">
                    <i>{{ $t('settings.changeNicknameDesc') }}</i>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="list-item row" v-for="(resident, index) in residents" :key="resident.id">
                <div class="col s8">
                    <p>{{ resident.fname }} {{ resident.lname }} ({{ resident.nickname }})</p>
                    <input style="height: 1.3rem;" type="text" v-model="personalNicknames[index].personalNickname" />
                </div>
                <div class="col s4 right-align">
                    <a class="waves-effect waves-light btn" style="margin-top: 20px;" @click="saveNickname(resident.id)">
                        <font-awesome-icon :icon="['fas', 'save']"></font-awesome-icon> Save
                    </a>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import store from '../../store'
import axios from 'axios'

export default {
    data() {
        return {
            settings: JSON.parse(store.state.user.settings),
            residents: store.state.residents,
            user: store.state.user,
            personalNicknames: []
        }
    },
    mounted() {
        this.residents.forEach(resident => {
            this.personalNicknames.push({
                id: resident.id,
                personalNickname: resident.pivot.personal_nickname || null
            })
        })
    },
    computed: {
        currentStatusDefaultSharedValue: {
            get() {
                return this.settings.posts.defaultSharedValue
            },
            set(newValue) {
                if(!newValue) {
                    // store false for defaultSharedValue
                    this.settings.posts.defaultSharedValue = false
                } else {
                    this.settings.posts.defaultSharedValue = true
                }
                this.saveSettingsToDatabase()
            }
        }
    },
    methods: {
        saveNickname(residentId) {
            // console.log('save personal nickname for this user (' + this.user.id + ') / resident (' + residentId + ')')
            // console.log('we want it to be ' + this.personalNicknames.find((resident) => resident.id == residentId).personalNickname)
            axios.post(process.env.VUE_APP_SERVER_URL + '/api/update-personal-nickname', {
                residentId,
                userId: this.user.id,
                personalNickname: this.personalNicknames.find((resident) => resident.id == residentId).personalNickname
            }, {
                headers: {
                    'Authorization': 'Bearer ' + store.state.auth.auth_token
                }
            } ).then((res) => {
                store.dispatch('fetchLinkedResidents').then(() => {
                    swal('Opgeslaan', 'Nieuwe bijnaam is opgeslaan!', 'success').then((value) => {
                        this.$router.push('/')
                    })
                })
            }).catch((err) => {
                swal(err, this.$t('beforeUpload.errorCaption'), 'error')
            })
        },
        saveSettingsToDatabase() {
            // console.log('store the following object to the server')
            // console.log(this.settings)
            this.$store.dispatch('saveUserSettings', {
                settings: this.settings
            })
        },
    },
}
</script>

<style scoped>

</style>